<section class="container pia-authenticationBlock">
  <div class="row">
    <div class="small-12 medium-8 columns pia-authenticationBlock-logo">
      <div>
        <img src="assets/images/pia-auth-logo.png" alt="Logo PIA" />
        <div>
          <h1 [innerHTML]="'authentication.title' | translate"></h1>
          <div>{{ "header.logo_subtitle" | translate }}</div>
        </div>
      </div>
      <div>
        <h2 [innerHTML]="'authentication.subtitle1' | translate"></h2>
        <p>{{ "authentication.subtitle2" | translate }}</p>
      </div>
    </div>
    <div class="small-12 medium-4 columns">
      <div class="pia-authenticationBlock-auth">
        <div *ngIf="authService.state; else elseBlock">
          <div [ngSwitch]="stepForm">
            <!-- Initial block / Sign in -->
            <ng-container *ngSwitchCase="'logIn'">
              <app-sign-in
                [fromSignUp]="fromValidation"
                (validated)="onSubmited()"
                (canceled)="changeDisplay('checkUuid')"
                (forget)="changeDisplay('forgetPassword')"
              ></app-sign-in>
            </ng-container>

            <!-- New account - Activation code -->
            <ng-container *ngSwitchCase="'checkUuid'">
              <app-uuid
                (canceled)="changeDisplay('logIn')"
                (validated)="changeDisplay('signUp'); accountData = $event"
              ></app-uuid>
            </ng-container>

            <!-- New account - Define password -->
            <ng-container *ngSwitchCase="'signUp'">
              <app-password
                (validated)="changeDisplay('logIn'); fromValidation = $event"
                (canceled)="changeDisplay('checkUuid')"
                [accountData]="accountData"
              ></app-password>
            </ng-container>

            <!-- Account recovery - Email -->
            <ng-container *ngSwitchCase="'forgetPassword'">
              <app-forget-password
                (canceled)="changeDisplay('logIn')"
                (validated)="changeDisplay('resetPassword')"
              ></app-forget-password>
            </ng-container>

            <!-- Account recovery - Reset code -->
            <ng-container *ngSwitchCase="'resetPassword'">
              <app-uuid
                [reset]="true"
                (canceled)="changeDisplay('forgetPassword')"
                (validated)="changeDisplay('newPassword'); accountData = $event"
              ></app-uuid>
            </ng-container>

            <!-- Account recovery - New password -->
            <ng-container *ngSwitchCase="'newPassword'">
              <app-password
                [reset]="true"
                (canceled)="changeDisplay('resetPassword')"
                (validated)="changeDisplay('logIn'); fromValidation = $event"
                [accountData]="accountData"
              ></app-password>
            </ng-container>
          </div>
        </div>
        <ng-template #elseBlock>
          <div class="pia-authenticationBlock-enter">
            <div>{{ "authentication.start_app" | translate }}</div>
            <p
              class="pia-authenticationBlock-warning"
              [innerHTML]="'authentication.description' | translate"
            ></p>
            <a routerLink="/entries" class="btn btn-green">{{
              "authentication.start" | translate
            }}</a>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</section>
<app-dialog></app-dialog>
