<div class="pia-cardsBlock pia" [attr.data-id]="structure.id">
  <div class="pia-cardsBlock-item pia-doingBlock-item">
    <div class="pia-cardsBlock-toolbar">
      <div class="pia-cardsBlock-toolbar-export">
        <a
          href="javascript:;"
          (click)="export(structure.id)"
          class="btn pia-tooltip"
        >
          <i class="fa fa-download" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.cards.item.tools.export' | translate }}"
            class="pia-tooltip-text"
            >{{ "homepage.cards.item.tools.export" | translate }}</span
          >
        </a>
        <a
          href="javascript:;"
          (click)="duplicate(structure.id)"
          class="btn pia-tooltip"
        >
          <i class="fa fa-files-o" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.cards.item.tools.duplicate' | translate }}"
            class="pia-tooltip-text"
            >{{ "homepage.cards.item.tools.duplicate" | translate }}</span
          >
        </a>
        <a
          *ngIf="!structure.is_example"
          href="javascript:;"
          (click)="remove(structure.id)"
          class="btn pia-tooltip"
        >
          <i class="fa fa-trash-o" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.cards.item.tools.remove' | translate }}"
            class="pia-tooltip-text"
            >{{ "homepage.cards.item.tools.remove" | translate }}</span
          >
        </a>
      </div>
    </div>

    <form class="pia-cardsBlock-item-form" [formGroup]="structureForm">
      <input type="hidden" formControlName="id" />
      <div (click)="structureNameFocusIn()" style="margin-top:20px;">
        <label for="structure-edit-{{ structure.id }}-name">{{
          "homepage.cards.structure_name" | translate
        }}</label>
        <input
          type="text"
          #structureName
          formControlName="name"
          required
          placeholder="{{
            'homepage.cards.placeholder_structure_name' | translate
          }}"
          id="structure-edit-{{ structure.id }}-name"
          (focusout)="structureNameFocusOut()"
        />
      </div>
      <div (click)="structureSectorNameFocusIn()">
        <label for="structure-edit-{{ structure.id }}-sector-name">{{
          "homepage.cards.sector_name" | translate
        }}</label>
        <input
          type="text"
          #structureSectorName
          formControlName="sector_name"
          required
          placeholder="{{
            'homepage.cards.placeholder_sector_name' | translate
          }}"
          id="structure-edit-{{ structure.id }}-sector-name"
          (focusout)="structureSectorNameFocusOut()"
        />
      </div>
      <div class="pia-cardsBlock-item-date">
        <div>{{ "homepage.cards.date" | translate }}</div>
        <time>{{
          structure.created_at
            | date: "shortDate":"":languagesService.selectedLanguage
        }}</time>
      </div>
      <div class="pia-cardsBlock-item-btn">
        <a
          *ngIf="structure.is_example"
          [routerLink]="['/structures', structure.id, 'section', 1, 'item', 1]"
          class="btn btn-green"
          >{{ "homepage.cards.item.consult_structure" | translate }}</a
        >
        <a
          *ngIf="!structure.is_example"
          [routerLink]="['/structures', structure.id, 'section', 1, 'item', 1]"
          class="btn btn-green"
          >{{ "homepage.cards.item.edit_structure" | translate }}</a
        >
      </div>
    </form>
  </div>
</div>
