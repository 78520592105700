<div class="pia-cardsBlock pia" [attr.data-id]="base.id">
  <div class="pia-cardsBlock-item pia-doingBlock-item">
    <div class="pia-cardsBlock-toolbar">
      <div class="pia-cardsBlock-toolbar-export" *ngIf="!base.is_example">
        <a
          href="javascript:;"
          (click)="export(base.id)"
          class="btn pia-tooltip"
        >
          <i class="fa fa-download" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.cards.item.tools.export' | translate }}"
            class="pia-tooltip-text"
          >
            {{ "homepage.cards.item.tools.export" | translate }}
          </span>
        </a>

        <a
          href="javascript:;"
          (click)="duplicate(base.id)"
          class="btn pia-tooltip"
        >
          <i class="fa fa-files-o" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.cards.item.tools.duplicate' | translate }}"
            class="pia-tooltip-text"
          >
            {{ "homepage.cards.item.tools.duplicate" | translate }}
          </span>
        </a>

        <a
          href="javascript:;"
          (click)="remove(base.id)"
          class="btn pia-tooltip"
        >
          <i class="fa fa-trash-o" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.cards.item.tools.remove' | translate }}"
            class="pia-tooltip-text"
          >
            {{ "homepage.cards.item.tools.remove" | translate }}
          </span>
        </a>
      </div>
    </div>

    <form class="pia-cardsBlock-item-form" [formGroup]="knowledgeBaseForm">
      <input type="hidden" formControlName="id" />
      <div (click)="knowledgeBaseNameFocusIn()" style="margin-top:20px;">
        <label for="knowledgebase-edit-{{ base.id }}-name">{{
          "homepage.cards.knowledges.placeholder_name" | translate
        }}</label>
        <input
          type="text"
          formControlName="name"
          required
          placeholder="{{
            'homepage.cards.knowledges.placeholder_name' | translate
          }}"
          id="knowledgebase-edit-{{ base.id }}-name"
          (focusout)="knowledgeBaseFocusOut()"
        />
      </div>

      <div (click)="knowledgeBaseAuthorFocusIn()">
        <label for="knowledgebase-edit-{{ base.id }}-author-name">{{
          "homepage.cards.knowledges.placeholder_author" | translate
        }}</label>
        <input
          type="text"
          formControlName="author"
          required
          placeholder="{{
            'homepage.cards.knowledges.placeholder_author' | translate
          }}"
          id="knowledgebase-edit-{{ base.id }}-author-name"
          (focusout)="knowledgeBaseFocusOut()"
        />
      </div>

      <div (click)="knowledgeBaseContributorsFocusIn()">
        <label for="knowledgebase-edit-{{ base.id }}-contributor-names">{{
          "homepage.cards.knowledges.placeholder_contributors" | translate
        }}</label>
        <input
          type="text"
          formControlName="contributors"
          required
          placeholder="{{
            'homepage.cards.knowledges.placeholder_contributors' | translate
          }}"
          id="knowledgebase-edit-{{ base.id }}-contributor-names"
          (focusout)="knowledgeBaseFocusOut()"
        />
      </div>

      <div>
        <label for="knowledgebase-edit-name">{{
          "homepage.cards.knowledges.placeholder_entry_number" | translate
        }}</label>
        <br />{{ nbEntries }}
      </div>

      <div class="pia-cardsBlock-item-btn">
        <a [routerLink]="['/knowledge_bases', base.id]" class="btn btn-red">
          <ng-container *ngIf="!base.is_example; else elseblock">
            {{ "homepage.cards.knowledges.edit" | translate }}
          </ng-container>
          <ng-template #elseblock>
            {{ "homepage.cards.item.consult_pia" | translate }}
          </ng-template>
        </a>
      </div>
    </form>
  </div>
</div>
