<div *ngIf="showValidationHistory()" class="pia-validationHistoryBlock">
  <div class="pia-validationHistoryBlock-title">
    <p>{{ "rejection_page.title" | translate }}</p>
    <div>
      <button
        class="btn pia-historyBlock-btn"
        type="button"
        (click)="displayHistoryList()"
        title="{{ 'misc.display' | translate }}"
      >
        <span class="pia-icons pia-icon-accordion-up"></span>
      </button>
    </div>
  </div>
  <div class="pia-validationHistoryBlock-content">
    <article>
      <time>{{
        pia.updated_at | date: "shortDate":"":languagesService.selectedLanguage
      }}</time>
      <h4>{{ "rejection_page.rejection_reason" | translate }}</h4>
      <p>{{ pia.rejection_reason }}</p>
      <h4>{{ "rejection_page.applied_adjustments" | translate }}</h4>
      <p>{{ pia.applied_adjustments }}</p>
    </article>
  </div>
</div>
<div *ngIf="showRejectionHistory()" class="pia-validationHistoryBlock-refuse">
  <div class="pia-validationHistoryBlock-refuse-title">
    <p>{{ "rejection_page.previous_rejection" | translate }}</p>
    <div>
      <button
        class="btn pia-refuseBlock-btn "
        type="button"
        (click)="displayRefuseList()"
        title="{{ 'misc.display' | translate }}"
      >
        <span class="pia-icons pia-icon-accordion-up"></span>
      </button>
    </div>
  </div>
  <div class="pia-validationHistoryBlock-refuse-content">
    <article>
      <time>{{
        pia.updated_at | date: "shortDate":"":languagesService.selectedLanguage
      }}</time>
      <h4>{{ "rejection_page.rejection_reason" | translate }}</h4>
      <p>{{ pia.rejection_reason }}</p>
      <h4>{{ "rejection_page.applied_adjustments" | translate }}</h4>
      <p>{{ pia.applied_adjustments }}</p>
    </article>
  </div>
</div>
