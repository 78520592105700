<td class="pia-listsBlock-item">
  <div class="pia-listsBlock-item-actions">
    <a *ngIf="structure.is_example; else pencilBlock"
      [routerLink]="['/structures', structure.id, 'section', 1, 'item', 1]" class="btn pia-tooltip">
      <i class="fa fa-eye" aria-hidden="true"></i>
      <span title="{{ 'homepage.cards.item.consult_pia' | translate }}" class="pia-tooltip-text">{{ 'homepage.cards.item.consult_pia' | translate }}</span>
    </a>
    <ng-template #pencilBlock>
      <a [routerLink]="['/structures', structure.id, 'section', 1, 'item', 1]" class="btn pia-tooltip">
        <i class="fa fa-pencil" aria-hidden="true"></i>
        <span title="{{ 'homepage.lists.item.tools.edit' | translate }}" class="pia-tooltip-text">{{ 'homepage.lists.item.tools.edit' | translate }}</span>
      </a>
    </ng-template>
    <a href="javascript:;" (click)="export(structure.id)" class="btn pia-tooltip">
      <i class="fa fa-download" aria-hidden="true"></i>
      <span title="{{ 'homepage.lists.item.tools.export' | translate }}" class="pia-tooltip-text">{{ 'homepage.lists.item.tools.export' | translate }}</span>
    </a>
    <a href="javascript:;" (click)="duplicate(structure.id)" class="btn pia-tooltip">
      <i class="fa fa-files-o" aria-hidden="true"></i>
      <span title="{{ 'homepage.lists.item.tools.duplicate' | translate }}" class="pia-tooltip-text">{{ 'homepage.lists.item.tools.duplicate' | translate }}</span>
    </a>
    <a href="javascript:;" (click)="remove(structure.id)" class="btn pia-tooltip" *ngIf="!structure.is_example">
      <i class="fa fa-trash-o" aria-hidden="true"></i>
      <span title="{{ 'homepage.lists.item.tools.remove' | translate }}" class="pia-tooltip-text">{{ 'homepage.lists.item.tools.remove' | translate }}</span>
    </a>
  </div>
</td>
<td class="pia-listsBlock-item">
  <div *ngIf="!structure.is_example;else readOnlyName" contenteditable (focusout)="onFocusOut('name', $event)">{{ structure.name }}</div>
  <ng-template #readOnlyName>
    <div>{{ structure.name }}</div>
  </ng-template>
</td>
<td class="pia-listsBlock-item"><time *ngIf="structure.updated_at && structure.updated_at.toString() !== 'Invalid Date'">{{ structure.updated_at | date: "shortDate":"":languagesService.selectedLanguage }}</time></td>
<td class="pia-listsBlock-item">
  <div *ngIf="!structure.is_example;else readOnlySectorName" contenteditable (focusout)="onFocusOut('sector_name', $event)">{{ structure.sector_name }}</div>
  <ng-template #readOnlySectorName>
    <div>{{ structure.sector_name }}</div>
  </ng-template>
</td>
<td class="pia-listsBlock-item"><time>{{ structure.created_at | date: "shortDate":"":languagesService.selectedLanguage }}</time></td>
