<form
  [formGroup]="forgetPassword"
  class="pia-authenticationBlock-login"
  (ngSubmit)="ngOnSubmit()"
>
  <fieldset>
    <div
      class="form-group"
      [class.is-not-empty]="f.email.value.length > 0"
      [class.is-invalid]="f.email.value.length > 0 && f.email.hasError('email')"
    >
      <legend>{{ "authentication.account_recovery" | translate }}</legend>
      <p class="msg" *ngIf="msgFromBack">
        {{
          "authentication.errors.account_recovery.email." + msgFromBack
            | translate
        }}
      </p>
      <input
        type="text"
        placeholder="{{ 'authentication.email_placeholder' | translate }}"
        id="pia-account-email"
        formControlName="email"
        email
      />
      <label
        *ngIf="f.email.hasError('email'); else emailBlock"
        for="pia-account-email"
      >
        {{ "authentication.wrong_email" | translate }}
      </label>
      <ng-template #emailBlock>
        <label for="pia-account-email">
          {{ "authentication.email" | translate }}
        </label>
      </ng-template>
    </div>
    <div class="validForm">
      <button (click)="onCanceled()">
        {{ "authentication.cancel" | translate }}
      </button>
      <button
        [disabled]="forgetPassword.invalid || loading"
        type="submit"
        class="btn btn-green"
      >
        <ng-container *ngIf="loading; else loadingElse">
          ...
        </ng-container>
        <ng-template #loadingElse>
          {{ "authentication.continue" | translate }}
        </ng-template>
      </button>
    </div>
  </fieldset>
</form>
