<ng-container *ngIf="!pia.is_example">
  <td class="pia-listsBlock-item">
    <div class="pia-listsBlock-item-actions">
      <a
        *ngIf="pia.status === 0 || pia.status === 1"
        [routerLink]="['/pia', pia.id, 'section', 1, 'item', 1]"
        class="btn pia-tooltip"
      >
        <i class="fa fa-pencil" aria-hidden="true"></i>
        <span
          title="{{ 'homepage.lists.item.tools.edit' | translate }}"
          class="pia-tooltip-text"
          >{{ "homepage.lists.item.tools.edit" | translate }}</span
        >
      </a>
      <a
        *ngIf="pia.status != 0 && pia.status != 1"
        [routerLink]="['/preview', pia.id]"
        class="btn pia-tooltip"
      >
        <i class="fa fa-eye" aria-hidden="true"></i>
        <span
          title="{{ 'homepage.lists.item.tools.consult' | translate }}"
          class="pia-tooltip-text"
          >{{ "homepage.lists.item.tools.consult" | translate }}</span
        >
      </a>
      <ng-container
        *ngIf="
          !authService.state ||
          authService.currentUserValue.access_type.includes('technical') ||
          authService.currentUserValue.access_type.includes('functional')
        "
      >
        <a
          href="javascript:;"
          (click)="onDuplicate(pia.id)"
          class="btn pia-tooltip"
        >
          <i class="fa fa-files-o" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.lists.item.tools.duplicate' | translate }}"
            class="pia-tooltip-text"
            >{{ "homepage.lists.item.tools.duplicate" | translate }}</span
          >
        </a>
        <a href="javascript:;" (click)="generateZip()" class="btn pia-tooltip">
          <i class="fa fa-download" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.lists.item.tools.export' | translate }}"
            class="pia-tooltip-text"
            >{{ "homepage.lists.item.tools.export" | translate }}</span
          >
        </a>
        <a
          href="javascript:;"
          (click)="archivePia(pia.id)"
          class="btn pia-tooltip"
        >
          <i class="fa fa-archive" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.lists.item.tools.archive' | translate }}"
            class="pia-tooltip-text"
            >{{ "homepage.lists.item.tools.archive" | translate }}</span
          >
        </a>
      </ng-container>
    </div>
  </td>
  <td class="pia-listsBlock-item pia-listsBlock-item-title">
    <div contenteditable (focusout)="onFocusOut('name', $event)">
      {{ pia.name }}
    </div>
    <div
      *ngIf="attachments && attachments.length > 0"
      class="pia-listsBlock-item-attachment"
      aria-hidden="true"
    >
      <span class="pia-icons pia-icon-attachment"></span>
      <ul>
        <ng-container *ngFor="let attachment of attachments">
          <li>{{ attachment.name }}</li>
        </ng-container>
      </ul>
    </div>
  </td>
  <td class="pia-listsBlock-item">
    <time
      *ngIf="pia.updated_at && pia.updated_at.toString() !== 'Invalid Date'"
      >{{
        pia.updated_at | date: "shortDate":"":languagesService.selectedLanguage
      }}</time
    >
  </td>
  <td class="pia-listsBlock-item">
    <div
      class="tag-input-container"
      *ngIf="authService.state; else author_name"
    >
      <tag-input
        #input
        [disable]="
          authService.currentUserValue &&
          !authService.currentUserValue.access_type.includes('functional')
        "
        identifyBy="id"
        (onAdd)="onAddUser($event, 'authors')"
        (onRemove)="onRemove($event, 'authors')"
        [ngModel]="authors"
        [theme]="'foundation-theme'"
        (onTextChange)="onTyped($event, pia.id, 'authors')"
        [editable]="false"
        [clearOnBlur]="true"
        [placeholder]="'homepage.cards.placeholder_validation' | translate"
        [secondaryPlaceholder]="
          'homepage.cards.placeholder_validation' | translate
        "
      >
        <ng-template let-item="item" let-index="index">
          <div
            [ngClass]="{
              'custom-tag': true,
              'user-not-found': item.id == null
            }"
          >
            {{ item.display }}
            <delete-icon (click)="input.removeItem(item, index)"></delete-icon>
          </div>
        </ng-template>
        <tag-input-dropdown
          [keepOpen]="false"
          identifyBy="id"
          [showDropdownIfEmpty]="true"
          [autocompleteItems]="userList"
        ></tag-input-dropdown>
      </tag-input>
      <button
        class="btn btn-add-user"
        (click)="
          onAddUser(
            {
              display: addBtnForSpecificInput.display,
              id: addBtnForSpecificInput.display
            },
            'author_name'
          )
        "
        *ngIf="
          addBtnForSpecificInput &&
          addBtnForSpecificInput.pia_id === pia.id &&
          addBtnForSpecificInput.field === 'author_name'
        "
      >
        +
      </button>
    </div>
    <ng-template #author_name>
      <div contenteditable (focusout)="onFocusOut('author_name', $event)">
        {{ pia.author_name }}
      </div>
    </ng-template>
  </td>
  <td class="pia-listsBlock-item">
    <div
      class="tag-input-container"
      *ngIf="authService.state; else evaluator_name"
    >
      <tag-input
        #input
        [disable]="
          authService.currentUserValue &&
          !authService.currentUserValue.access_type.includes('functional')
        "
        identifyBy="id"
        (onAdd)="onAddUser($event, 'evaluators')"
        (onRemove)="onRemove($event, 'evaluators')"
        [ngModel]="evaluators"
        [theme]="'foundation-theme'"
        (onTextChange)="onTyped($event, pia.id, 'evaluators')"
        [editable]="false"
        [clearOnBlur]="true"
        [placeholder]="'homepage.cards.placeholder_validation' | translate"
        [secondaryPlaceholder]="
          'homepage.cards.placeholder_validation' | translate
        "
      >
        <ng-template let-item="item" let-index="index">
          <div
            [ngClass]="{
              'custom-tag': true,
              'user-not-found': item.id == null
            }"
          >
            {{ item.display }}
            <delete-icon (click)="input.removeItem(item, index)"></delete-icon>
          </div>
        </ng-template>
        <tag-input-dropdown
          [keepOpen]="false"
          identifyBy="id"
          [showDropdownIfEmpty]="true"
          [autocompleteItems]="userList"
        ></tag-input-dropdown>
      </tag-input>
      <button
        class="btn btn-add-user"
        (click)="
          onAddUser(
            {
              display: addBtnForSpecificInput.display,
              id: addBtnForSpecificInput.display
            },
            'evaluator_name'
          )
        "
        *ngIf="
          addBtnForSpecificInput &&
          addBtnForSpecificInput.pia_id === pia.id &&
          addBtnForSpecificInput.field === 'evaluator_name'
        "
      >
        +
      </button>
    </div>
    <ng-template #evaluator_name>
      <div contenteditable (focusout)="onFocusOut('evaluator_name', $event)">
        {{ pia.evaluator_name }}
      </div>
    </ng-template>
  </td>
  <td class="pia-listsBlock-item">
    <div
      class="tag-input-container"
      *ngIf="authService.state; else validator_name"
    >
      <tag-input
        #input
        [disable]="
          authService.currentUserValue &&
          !authService.currentUserValue.access_type.includes('functional')
        "
        identifyBy="id"
        (onAdd)="onAddUser($event, 'validators')"
        (onRemove)="onRemove($event, 'validators')"
        [ngModel]="validators"
        [theme]="'foundation-theme'"
        (onTextChange)="onTyped($event, pia.id, 'validators')"
        [editable]="false"
        [clearOnBlur]="true"
        [placeholder]="'homepage.cards.placeholder_validation' | translate"
        [secondaryPlaceholder]="
          'homepage.cards.placeholder_validation' | translate
        "
      >
        <ng-template let-item="item" let-index="index">
          <div
            [ngClass]="{
              'custom-tag': true,
              'user-not-found': item.id == null
            }"
          >
            {{ item.display }}
            <delete-icon (click)="input.removeItem(item, index)"></delete-icon>
          </div>
        </ng-template>
        <tag-input-dropdown
          [keepOpen]="false"
          identifyBy="id"
          [showDropdownIfEmpty]="true"
          [autocompleteItems]="userList"
        ></tag-input-dropdown>
      </tag-input>
      <button
        class="btn btn-add-user"
        (click)="
          onAddUser(
            {
              display: addBtnForSpecificInput.display,
              id: addBtnForSpecificInput.display
            },
            'validator_name'
          )
        "
        *ngIf="
          addBtnForSpecificInput &&
          addBtnForSpecificInput.pia_id === pia.id &&
          addBtnForSpecificInput.field === 'validator_name'
        "
      >
        +
      </button>
    </div>
    <ng-template #validator_name>
      <div contenteditable (focusout)="onFocusOut('validator_name', $event)">
        {{ pia.validator_name }}
      </div>
    </ng-template>
  </td>
  <td class="tag-input-container" *ngIf="authService.state">
    <tag-input
      #input
      [disable]="
        authService.currentUserValue &&
        !authService.currentUserValue.access_type.includes('functional')
      "
      identifyBy="id"
      (onAdd)="onAddUser($event, 'guests')"
      (onRemove)="onRemove($event, 'guests')"
      [ngModel]="guests"
      [theme]="'foundation-theme'"
      (onTextChange)="onTyped($event, pia.id, 'guests')"
      [editable]="false"
      [clearOnBlur]="true"
      [placeholder]="'homepage.cards.placeholder_validation' | translate"
      [secondaryPlaceholder]="
        'homepage.cards.placeholder_validation' | translate
      "
    >
      <ng-template let-item="item" let-index="index">
        <div [ngClass]="{ 'custom-tag': true }">
          {{ item.display }}
          <delete-icon (click)="input.removeItem(item, index)"></delete-icon>
        </div>
      </ng-template>
      <tag-input-dropdown
        [keepOpen]="false"
        identifyBy="id"
        [showDropdownIfEmpty]="true"
        [autocompleteItems]="usersForGuests"
      ></tag-input-dropdown>
    </tag-input>
    <button
      class="btn btn-add-user"
      (click)="
        onAddUser(
          {
            display: addBtnForSpecificInput.display,
            id: addBtnForSpecificInput.display
          },
          'guests'
        )
      "
      *ngIf="
        addBtnForSpecificInput &&
        addBtnForSpecificInput.pia_id === pia.id &&
        addBtnForSpecificInput.field === 'guests'
      "
    >
      +
    </button>
  </td>
  <td class="pia-listsBlock-item">
    <div *ngIf="pia.structure_id">{{ pia.structure_name }}</div>
  </td>
  <td class="pia-listsBlock-item progress">
    <div class="pia-cardsBlock-item-status-progressBar">
      <span title="{{ piaService.getStatusName(pia.status) | translate }}">{{
        piaService.getStatusName(pia.status) | translate
      }}</span>
      <progress max="100" value="{{ pia.progress }}"></progress>
    </div>
  </td>
</ng-container>
