<div class="pia-modalBlock open" [ngClass]="specialClass">
  <div class="pia-modalBlock-content bounceIn">
    <button tabindex="1" type="button" class="pia-modalBlock-close btn"
      title="{{ 'homepage.cards.title_close_creation' | translate }}"
      (click)="clickOnClose.emit()"
      >
        <span class="pia-icons pia-icon-close"></span>
    </button>
    <ng-content></ng-content>
  </div>
</div>
