<div
  class="pia-questionBlock"
  [ngClass]="{ approved: evaluation.status === 2 || evaluation.status === 3 }"
  [attr.data-id]="question.id"
>
  <div
    [innerHTML]="question.title | translate"
    class="pia-questionBlock-title"
  ></div>
  <button
    class="pia-icons pia-accordion pia-icon-accordion-up"
    type="button"
    (click)="displayQuestion($event)"
    title="{{ 'misc.display' | translate }}"
  ></button>
  <div
    class="pia-questionBlock-displayer fadeIn"
    *ngIf="!loading; else loadingElse"
  >
    <form [formGroup]="questionForm">
      <!-- Gauge -->
      <div class="pia-gaugeBlock" *ngIf="question.answer_type == 'gauge'">
        <input
          type="range"
          (change)="checkGaugeChanges($event)"
          [attr.disabled]="
            (!editMode.includes('author') && editMode !== 'local') ||
            !globalEvaluationService.answerEditionEnabled
              ? 'true'
              : null
          "
          formControlName="gauge"
          min="0"
          max="4"
          step="1"
        />
        <div>
          <div>{{ "questions.gauges.0" | translate }}</div>
          <div>{{ "questions.gauges.1" | translate }}</div>
          <div>{{ "questions.gauges.2" | translate }}</div>
          <div>{{ "questions.gauges.3" | translate }}</div>
          <div>{{ "questions.gauges.4" | translate }}</div>
        </div>
        <div
          class="pia-gaugeBlock-background {{
            questionForm.value.gauge
              ? 'pia-gaugeBlock-background-' + questionForm.value.gauge
              : ''
          }}"
        ></div>
      </div>

      <!-- Tags list -->
      <div class="pia-questionBlock-content">
        <ng-container *ngIf="question.is_measure; else tagInput2">
          <tag-input
            *ngIf="question.answer_type == 'list'"
            (onAdd)="onAdd($event)"
            (onTagEdited)="onTagEdited($event)"
            (onRemove)="onRemove($event)"
            formControlName="list"
            [clearOnBlur]="true"
            [theme]="'foundation-theme'"
            [placeholder]="question.placeholder | translate"
            [editable]="false"
            [disable]="
              !globalEvaluationService.answerEditionEnabled ||
              (!editMode.includes('author') && editMode !== 'local')
            "
            [onlyFromAutocomplete]="true"
            (focusin)="questionContentFocusIn()"
            [secondaryPlaceholder]="question.placeholder | translate"
          >
            <tag-input-dropdown
              [showDropdownIfEmpty]="true"
              [autocompleteItems]="userMeasures"
            ></tag-input-dropdown>
          </tag-input>
        </ng-container>
        <ng-template #tagInput2>
          <tag-input
            *ngIf="question.answer_type == 'list'"
            (onAdd)="onAdd($event)"
            (onSelect)="onSelected($event)"
            (onTagEdited)="onTagEdited($event)"
            (onRemove)="onRemove($event)"
            [addOnBlur]="true"
            [separatorKeyCodes]="[27, 13]"
            formControlName="list"
            [theme]="'foundation-theme'"
            [placeholder]="question.placeholder | translate"
            (focusin)="questionContentFocusIn()"
            [editable]="true"
            [disable]="
              !globalEvaluationService.answerEditionEnabled ||
              (!editMode.includes('author') && editMode !== 'local')
            "
            [secondaryPlaceholder]="question.placeholder | translate"
          >
            <tag-input-dropdown
              [showDropdownIfEmpty]="true"
              [autocompleteItems]="userAnswersToDisplay"
            ></tag-input-dropdown>
          </tag-input>
        </ng-template>
        <!-- Question answer -->
        <div
          (click)="questionContentFocusIn()"
          class="pia-questionBlock-contentText"
          [innerHTML]="questionForm.controls['text'].value | safeHtml"
          [ngClass]="{ hide: editor || !questionForm.controls['text'].value }"
        ></div>
        <textarea
          *ngIf="
            (question.answer_type == 'text' ||
              question.answer_type == 'gauge') &&
            (editMode.includes('author') || editMode === 'local')
          "
          [attr.data-id]="question.id"
          id="pia-question-content-{{ question.id }}"
          formControlName="text"
          name="pia-question"
          minRows="8"
          cols="50"
          [ngClass]="{
            hide:
              !editor &&
              questionForm.controls['text'].value &&
              questionForm.controls['text'].value.length > 0
          }"
          (focusin)="questionContentFocusIn()"
          placeholder="{{ question.placeholder | translate }}"
        >
        </textarea>
      </div>
    </form>
  </div>

  <app-comments
    [editMode]="editMode"
    *ngIf="answer && answer.id"
    [pia]="pia"
    [question]="question"
    [questionId]="question.id"
    [answer]="answer"
  >
  </app-comments>

  <app-evaluations
    [editMode]="editMode"
    (evaluationEvent)="evaluationChange($event)"
    *ngIf="item.evaluation_mode === 'question'"
    [pia]="pia"
    [section]="section"
    [item]="item"
    [questionId]="question.id"
  ></app-evaluations>
</div>

<ng-template #loadingElse>
  <div class="pia-sectionBlock-body" style="position:relative; height: 150px;">
    <app-loading-overlay
      [visibility]="true"
      [childMode]="true"
    ></app-loading-overlay>
  </div>
</ng-template>
