<section class="container pia-settingsBlock pia-mainContainerBlock">
  <div class="row">
    <div class="pia-breadcrumb">
      <strong
        >{{ "header.tools.settings" | translate }} >
        {{ "header.tools.url_setting" | translate }}</strong
      >
    </div>

    <div class="small-12 medium-12 columns">
      <div class="pia-settingsBlock-server">
        <h1>{{ "settings.title" | translate }}</h1>
        <form [formGroup]="settingsForm" (ngSubmit)="onSubmit()" novalidate>
          <label for="server_url">{{ "settings.content" | translate }}</label>
          <input
            type="text"
            id="server_url"
            formControlName="server_url"
            placeholder="https://"
            (blur)="purify('server_url', $event)"
          />
          <label for="client_id">Client ID :</label>
          <input
            type="text"
            id="client_id"
            formControlName="client_id"
            placeholder="Client ID"
            (blur)="purify('client_id', $event)"
          />
          <label for="client_secret">Client SECRET :</label>
          <input
            type="password"
            id="client_secret"
            formControlName="client_secret"
            placeholder="Client SECRET"
            (blur)="purify('client_secret', $event)"
          />
          <button
            *ngIf="!loading; else loadingElse"
            type="submit"
            class="btn btn-green"
            ngClass="{'btn--processing': formProcessing}"
          >
            {{ "settings.update" | translate }}
          </button>
          <ng-template #loadingElse>
            <div
              style="margin: auto; text-align: center; position:relative; height: 150px;"
            >
              <app-loading-overlay
                [visibility]="true"
                [childMode]="true"
              ></app-loading-overlay>
            </div>
          </ng-template>
        </form>
      </div>
    </div>
  </div>
</section>
<app-dialog></app-dialog>
