<div class="pia-risksOverviewBlock">
  <svg id="risksOverviewSvg" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <style type="text/css">
        svg {
          background-color: white;
        }
        .rect_action {
          cursor: pointer;
          fill: transparent;
        }
        .title {
          font-size: 1.3rem;
          font-weight: bold;
        }
        .rect_1 {
          fill: #000;
        }
        .rect_2 {
          fill: #a7a7a7;
        }
        .c32 {
          fill: #FD4664;
          font-weight: bold;
        }
        .c33 {
          fill: #3EE095;
          font-weight: bold;
        }
        .c34 {
          fill: #091C6B;
          font-weight: bold;
        }
        .path_32 {
          stroke: #FD4664;
          fill: transparent;
        }
        .path_33 {
          stroke: #3EE095;
          fill: transparent;
        }
        .path_34 {
          stroke: #091C6B;
          fill: transparent;
        }
        [data-right] {
          cursor: pointer;
        }
        .right_c text {
          fill: #a7a7a7 !important;
        }
        .gauge_prefix {
          text-transform: capitalize;
        }
        .right_c line.progress_bar_1,
        .right_c line.progress_bar_2,
        .right_c line.progress_bar_3,
        .right_c line.progress_bar_4 {
          stroke: #a7a7a7 !important;
        }
        .progress_bar_1 {
          stroke: #78b687;
        }
        .progress_bar_2 {
          stroke: #a2947f;
        }
        .progress_bar_3 {
          stroke: #d06f73;
        }
        .progress_bar_4 {
          stroke: #FD4664;
        }
      </style>
    </defs>
  </svg>
</div>
